<template>
  <div class="container">
    <Breadcrumb class="bg-transparent" :breadcrumbList="[
        { 'name': 'Admin Dashboard', 'link': $router.resolve({ name: 'admin' }).href },
        { 'name': 'USERS', 'link': '/admin/users'},
        { 'name': 'Watched List' }
      ]"/>
    <div class="main-container">
      <h1 class="font-weight-bold">Watched List</h1>
      <div class="bg-white border rounded p-3 mb-3">
        <div class="d-flex justify-content-center">
          <div class="input-group rounded">
            <input type="text" class="form-control" placeholder="Enter username" v-model="searchTerm">
            <div class="input-group-append">
              <button class="btn btn-primary" type="button" @click="getWatchedListed(1); handleSearchHistory()">Search</button>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <button type="button" class="btn btn-sm btn-outline-dark ml-2 rounded-pill" v-for="(history, index) in searchHistory" :key="'h-' + index"
                  @click="searchTerm = history; handleSearchHistory(); getWatchedListed(1)">{{ history }}
          </button>
        </div>
      </div>

      <div v-if="loaded">
        <div v-if="items.length === 0">
          <i class="fa-solid fa-5x fa-bridge-circle-exclamation mb-3"></i>
          <p>No results found</p>
        </div>
        <template v-else>
          <div class="box-title border-bottom mb-3">
            <span class="small">{{ paginationInfo }}</span>
          </div>
          <div class="bg-white border rounded p-4 mb-5 d-flex" v-for="(item, index) in items" :key="index">
            <div class="text-left col-4">
              <div class="d-inline-flex align-items-center w-100 justify-content-center mb-2">
                <router-link :to="{ name: 'admin_user', params: { hash: item.user_hash } }" target="_blank" class="mr-2 h4 m-0">{{ item.username }}</router-link> <small class="badge small badge-secondary" v-if="item.is_seller">SELLER</small>
              </div>
              <div class="border rounded p-4 mb-3">
                <div class="d-flex justify-content-between">
                  <span class="font-weight-bold">Last login:</span> {{ displayDatetime(item.last_active) }}
                </div>
                <div class="d-flex justify-content-between">
                  <span class="font-weight-bold">Last IP:</span> {{ item.ip_address }}
                </div>
                <div class="d-flex justify-content-between">
                  <span class="font-weight-bold">Country by IP:</span> {{ item.ip_country ? item.ip_country : 'N/A' }}
                </div>
                <hr/>
                <div class="d-flex justify-content-between">
                  <span class="font-weight-bold">Away:</span> {{ item.away ? 'Yes' : 'No' }}
                </div>
                <div class="d-flex justify-content-between">
                  <span class="font-weight-bold">SSO ID:</span> {{ item.electroneum_id ? item.electroneum_id : 'N/A' }}
                </div>
              </div>
              <div class="d-flex flex-col">
                <div class="mb-3 text-center" :class="checkLastLoginDate(item.watchlist_last_checked, item.last_active) ? 'text-danger' : ''">
                  <span>Last checked:</span> {{ displayDatetime(item.watchlist_last_checked) }}
                </div>
                <button class="btn btn-primary mb-3" @click="handleChecked(index)">Checked</button>
                <button class="btn btn-secondary mb-3" @click="handleNotes(item.watchlist_hash)">View/Add Notes</button>
                <button class="btn btn-danger mb-3" @click="handleRemove(index)">Remove</button>
              </div>
            </div>
            <div class="col-8 border-left">
              <div class="d-inline-flex justify-content-between mb-4 w-100">
                <div class="w-100 text-center">
                  <div>Orders</div>
                  {{ item.total_orders }}
                </div>
                <div class="w-100 text-center">
                  <div>Custom Tasks</div>
                  {{ item.total_custom_quotes }}
                </div>
                <div class="w-100 text-center">
                  <div>Message Threads</div>
                  {{ item.total_message_thread }}
                </div>
              </div>
              <div class="text-left">
                <div class="border rounded p-3">
                  <div class="d-flex justify-content-between small mb-3">
                    <div><span>Author:</span> {{ item.last_message_made_username }}</div>
                    <div><span>Last message sent:</span> {{ displayDatetime(item.last_message_made_date) }}</div>
                  </div>
                  <hr/>
                  <p>{{ item.last_message_made }}</p>
                </div>

                <div class="border rounded p-3 mt-3">
                  <div class="d-flex justify-content-between small mb-3">
                    <div><span>Author:</span> {{ item.last_message_received_username }}</div>
                    <div><span>Last message received:</span> {{ displayDatetime(item.last_message_received_date) }}</div>
                  </div>
                  <hr/>
                  <p>{{ item.last_message_received }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <Pagination v-if="pagination.total > pagination.per_page" :per-page="pagination.per_page" :current-page="pagination.page" :total="pagination.total"
                        v-on:paginationUpdate="getWatchedListed"/>
          </div>
        </template>
      </div>
      <div class="mx-auto my-5" v-else>
        <b-spinner large label="Large Spinner"></b-spinner>
        <div class="mt-2">loading data...</div>
      </div>
    </div>
    <admin-watched-list-notes-modal :watchlist-hash="selectedHash" v-if="selectedHash !== null"/>
  </div>
</template>
<script>
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'
import ApiService from '../../../apiService'
import AdminWatchedListNotesModal from '../../../components/Admin/WatchedList/AdminWatchedListNotesModal.vue'
import Pagination from '../../../components/Global/Pagination.vue'

export default {
  name: 'AdminUsersWatched',
  components: {
    Pagination,
    AdminWatchedListNotesModal,
    Breadcrumb
  },
  data () {
    return {
      loaded: false,
      searchTerm: '',
      searchHistory: [],
      items: [],
      selectedHash: null,
      pagination: {
        per_page: 10,
        page: 1,
        total: 0
      }
    }
  },
  mounted () {
    this.getWatchedListed()
    this.handleSearchHistory()
  },
  methods: {
    getWatchedListed (page = 1) {
      this.loaded = false
      let paramsData = {}
      if (this.searchTerm.trim() !== '') {
        paramsData.term = this.searchTerm
      }

      ApiService.getAdminWatchedList(paramsData, page).then((resp) => {
        this.items = resp.data.data.results
        this.pagination = resp.data.data.pagination
      }).catch(() => {
        // Empty
      }).finally(() => {
        this.loaded = true
      })
    },
    displayDatetime (date) {
      if (date === null) {
        return ''
      }
      const newDate = new Date(date)
      return newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) + ' ' + newDate.toLocaleDateString()
    },
    checkLastLoginDate (checkDate, lastLoginDate) {
      if (lastLoginDate == null) {
        return true
      }

      const convertCheckDate = new Date(checkDate).getTime()
      const convertLastLoginDate = new Date(lastLoginDate).getTime()

      return (convertCheckDate < convertLastLoginDate)
    },
    handleChecked (index) {
      const item = this.items[index]
      ApiService.patchAdminWatchedList(item.watchlist_hash).then(() => {
        item.watchlist_last_checked = new Date().toISOString()
      }).catch(() => {
        // Handle Errors
      })
    },
    handleNotes (hash) {
      this.selectedHash = hash
      setTimeout(() => {
        this.$bvModal.show('watched-list-notes-modal')
      }, 100)
    },
    handleRemove (index) {
      const item = this.items[index]
      if (confirm('Are you sure you like to remove this from the watch list?')) {
        ApiService.removeAdminWatchedList(item.watchlist_hash).then(() => {
          this.items.splice(index, 1)
        }).catch(() => {
        })
      }
    },
    handleSearchHistory () {
      let currentItems = JSON.parse(localStorage.getItem('watch_list'))
      if (currentItems === null) {
        currentItems = []
      } else {
        const index = currentItems.indexOf(this.searchTerm)
        if (index > -1) {
          currentItems.splice(index, 1)
        }
      }

      if (this.searchTerm.trim() !== '') {
        currentItems.push(this.searchTerm)
      }

      if (currentItems.length > 4) {
        currentItems.shift()
      }
      localStorage.setItem('watch_list', JSON.stringify(currentItems))
      this.searchHistory = currentItems
    }
  },
  computed: {
    paginationInfo () {
      const currentTotalPosition = this.pagination.per_page * (this.pagination.page - 1)
      return this.$t('showing', { from: currentTotalPosition + 1, to: this.items.length + currentTotalPosition, total: this.pagination.total })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <b-modal id="watched-list-notes-modal"
             title="Watch List Notes"
             title-class="font-weight-bold w-100"
             size="xl"
             :ok-only="true"
             @show="loadNotes"
             scrollable
    >
      <div class="m-3">
        <div class="" v-if="loaded">
          <div v-for="(item, index) in items" :key="index" class="border rounded bg-navy text-white p-3 mb-3">
            <span>{{ displayDatetime(item.date_created) }}:</span> {{ item.message }}
          </div>

          <div class="row">
            <div class="col-10">
              <textarea class="form-control" placeholder="Enter your note" v-model="message"></textarea>
            </div>
            <div class="col-2 align-content-center">
              <button class="btn btn-primary" @click="handleAddNote()">Add note</button>
            </div>
          </div>
        </div>
        <div class="mx-auto my-5 text-center" v-else>
          <b-spinner large label="Large Spinner"/>
          <div>Loading messages</div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '../../../apiService'

export default {
  name: 'AdminWatchedListNotesModal',
  props: {
    watchlistHash: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loaded: false,
      items: [],
      message: ''
    }
  },
  methods: {
    async loadNotes () {
      this.loaded = false
      await ApiService.getAdminWatchedListNotes(this.watchlistHash).then((resp) => {
        this.items = resp.data.data
      }).catch(() => {}).finally(() => {
        this.loaded = true
      })
    },
    handleAddNote () {
      ApiService.postAdminWatchedListNote(this.watchlistHash, this.message).then((resp) => {
        this.items.push(resp.data.data)
        this.message = ''
      }).catch(() => {})
    },
    displayDatetime (date) {
      if (date === null) {
        return ''
      }
      const newDate = new Date(date)
      return newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) + ' ' + newDate.toLocaleDateString()
    }
  }
}
</script>

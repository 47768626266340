<template>
  <div>
    <template v-if="loaded">
      <div v-if="items.length < 1">
        <i class="fa-solid fa-5x fa-suitcase mb-3"></i>
        <p>No jobs have been created</p>
      </div>
      <div v-else>
        <div class="col-12 text-right p-0 mb-2">
          Total: {{ pagination.total }}
        </div>
        <table class="table table-striped table-bordered add-manage-table bg-white">
          <thead>
          <tr>
            <th>{{ $t('job.my_account.headers.title') }}</th>
            <th>{{ $t('job.my_account.headers.budget') }}</th>
            <th>{{ $t('job.my_account.headers.expired_date') }}</th>
            <th>{{ $t('job.my_account.headers.offer') }}</th>
            <th>{{ $t('job.my_account.headers.status') }}</th>
            <th>Date Created</th>
            <th>Date Modified</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>{{ item.title }}</td>
            <td>
              <template v-if="item.price_from === null || item.price_to === null">{{ $t('job.my_account.no_price_set') }}</template>
              <template v-else>${{ item.price_from.toFixed(2) }} / ${{ item.price_to.toFixed(2) }}</template>
            </td>
            <td>
              {{ date(item.date_expires) }}
            </td>
            <td>
              {{ item.job_offer.length }}
            </td>
            <td>
              {{ $t(`job.status.${item.status}`) }}
            </td>
            <td>
              {{ date(item.date_created) }}
            </td>
            <td>
              {{ date(item.date_modified) }}
            </td>
          </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-center mt-4">
          <Pagination v-if="pagination.total > pagination.per_page" :per-page="pagination.per_page" :current-page="pagination.page" :total="pagination.total"
                      v-on:paginationUpdate="getJobs"/>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="mx-auto my-5">
        <b-spinner large label="Large Spinner"></b-spinner>
        <div class="mt-2">{{ $t('job.my_account.loading') }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import ApiService from '../../../apiService'
import Pagination from '../../Global/Pagination'

export default {
  name: 'AdminUserJobs',
  components: { Pagination },
  props: {
    username: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loaded: false,
      items: [],
      pagination: {
        per_page: 10,
        page: 1,
        total: 0
      }
    }
  },
  mounted () {
    this.getJobs(1)
  },
  methods: {
    async getJobs (page = 1) {
      this.loaded = false
      await ApiService.getAdminJobUser(this.username, page).then((resp) => {
        this.pagination = resp.data.data.pagination
        this.items = resp.data.data.results
        this.loaded = true
      }).catch((e) => {})
    },
    date (date) {
      if (date === null) {
        return this.$t('job.my_account.no_date_set')
      }
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    }
  }
}
</script>

<template>
  <div class="container mb-3 mt-3">
    <table class="table table-striped table-bordered add-manage-table" data-filter="#filter" data-filter-text-only="true">
      <thead>
      <tr>
        <th>Username</th>
        <th>Email</th>
        <th>SSO ID</th>
        <th>Balance</th>
        <th>Locked</th>
        <th>Shadow Banned</th>
        <th>Deleted</th>
        <th>Date Created</th>
      </tr>
      </thead>
      <tbody class="mb-md-0 mb-3 text-left">
      <tr v-for="(user, index) in users" :key="index" class="cursor-pointer" @click="$router.push('/admin/users/' + user.hash)">
        <td data-title="Username">
          <i class="fa-solid fa-magnifying-glass pr-2"></i> {{ user.username }}
        </td>
        <td data-title="Email">{{ user.email }}</td>
        <td data-title="SSO ID" class="text-center">
          {{ user.electroneum_id }}
        </td>
        <td data-title="Balance" class="text-center">{{ getTotalBalance(user.user_balance) }}</td>
        <td data-title="Locked" class="text-center">
          <i v-if="user.locked" class="fa-solid fa-lock locked" aria-hidden="true"/>
          <i v-else class="fa-solid fa-unlock locked text-gray" aria-hidden="true"/>
        </td>
        <td data-title="Shadow Ban" class="text-center">
          <i v-if="user.shadow_ban" class="fa-solid fa-eye-slash locked" aria-hidden="true"/>
          <i v-else class="fa-solid fa-eye-slash locked text-gray" aria-hidden="true"/>
        </td>
        <td data-title="Deleted" class="text-center">
          <i v-if="user.date_deleted" class="fa-solid fa-trash-can locked" aria-hidden="true"/>
          <i v-else class="fa-regular fa-trash-can locked text-gray" aria-hidden="true"/>
        </td>
        <td data-title="Date Created">{{ date(user.date_created) }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'AdminUsersAllTable',
  props: {
    users: Array
  },
  methods: {
    date (date) {
      if (date === null) {
        return 'no date available'
      }
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    },
    getTotalBalance: function (userBalance) {
      const today = new Date()
      let total = 0

      if (userBalance.length) {
        userBalance.forEach(function (item) {
          if (today <= (new Date(item.date_expired))) {
            total += item.amount
          }
        })
        if (total > 0) {
          return '$' + total.toFixed(2)
        }
      }
      return ''
    }
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  padding: 0;
}

li {
  float: left;
  margin-right: 20px;
}

.locked {
  font-size: 1.2em;
}

.text-gray {
  color: #bbb;
}

</style>

<template>
  <div class="container mb-3 mt-3">
    <table class="table table-striped table-bordered add-manage-table" data-filter="#filter" data-filter-text-only="true">
      <thead>
      <tr>
        <th>Username</th>
        <th>Email</th>
        <th>SSO ID</th>
        <th>Return Date</th>
        <th>Set by</th>
        <th>Start date</th>
        <th>Number of times set</th>
      </tr>
      </thead>
      <tbody class="mb-md-0 mb-3 text-left">
      <tr v-for="(user, index) in users" :key="index" class="cursor-pointer" @click="$router.push('/admin/users/' + user[0].hash)">
        <td data-title="Username">
          <i class="fa-solid fa-magnifying-glass pr-2"></i> {{ user[0].username }}
        </td>
        <td data-title="Email">{{ user[0].email }}</td>
        <td data-title="SSO ID" class="text-center">{{ user[0].electroneum_id }}</td>
        <td data-title="return date" class="text-center">
          {{ date(user[0].returning_date) }}
        </td>
        <td data-title="Set by" class="text-center">
          {{ user.set_by !== null ? user.set_by : 'Automatically assigned' }}
        </td>
        <td data-title="start date" class="text-center">
          {{ date(user.set_away) }}
        </td>
        <td data-title="Number of times" class="text-center">
          {{ user.times_away }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'AdminUsersAwayTable',
  props: {
    users: Array
  },
  methods: {
    date (date) {
      if (date === null) {
        return 'no date available'
      }
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    }
  }
}
</script>

<template>
  <b-tab :title="title" class="p-0 pb-3" id="dashboard">
    <div class="row mx-0">
      <div v-if="tasks.length === 0" class="pt-3 px-4 card-item">
        <p>{{ $t('search_no_tasks_message') }}</p>
      </div>
      <div v-else class="col-lg-4 col-md-6 col-sm-12 pt-3 px-sm-2 mt-0 mb-0 card-item" v-for="(task, index) in tasks" :key="task.hash">
        <div class="row m-0 p-2 border task-action-bottom-container align-items-center" style="border-color: rgba(0, 0, 0, 0.125); border-bottom: none !important; border-radius: 0; background-color: #f3f3f3 !important;">
          <div class="col-6 p-0 text-left align-items-center" style="line-height:1.2em;">
            <small class="mr-1">{{ $t('status') }}:</small><br />
            <span v-if="task.status === 1" class="badge badge-primary text-wrap">{{ $t('active') }}</span>
            <span v-else-if="task.status === 2" class="badge badge-warning text-wrap">{{ $t('pending') }}</span>
            <span v-else-if="task.status === 3" class="badge badge-danger text-wrap">{{ $t('declined_button') }}</span>
            <span v-else-if="task.status === 4" class="badge badge-dark text-wrap">{{ $t('unlisted') }}</span>
            <span v-else class="badge badge-secondary text-wrap">{{ $t('draft') }}</span>
          </div>
          <div class="col-6 p-0 text-right" v-if="dropdown">
            <b-dropdown class="ml-auto float-right bg-white" size="sm" right variant="outline-secondary">
              <template slot="button-content">{{ $t('actions') }}</template>
              <b-dropdown-item v-if="(task.status === 0 || task.status === 3) && $store.getters.getVerification() === 1" class="dropdown-options" v-on:click="confirmActivateTask(2, task.slug)">
                {{ $t('activate') }}
              </b-dropdown-item>
              <b-dropdown-item v-else-if="$store.getters.getVerification() === 1 && task.status !== 4" class="dropdown-options" v-on:click="confirmActivateTask(0, task.slug)">
                {{ $t('deactivate') }}
              </b-dropdown-item>
              <b-dropdown-divider v-if="$store.getters.getVerification() === 1 && task.status !== 4" class="p-0"/>
              <b-dropdown-item v-if="task.status !== 3" class="dropdown-options" v-on:click="$router.push({ name: 'editTask', params: { slug:  task.slug }})">
                {{ $t('edit') }}
              </b-dropdown-item>
              <b-dropdown-item v-else class="dropdown-options" v-on:click="$router.push({ name: 'editTask', params: { slug:  task.slug }})">
                {{ $t('why_is_this_declined') }}
              </b-dropdown-item>
              <b-dropdown-item class="dropdown-options" v-on:click="confirmDeleteTask(task.hash)">
                {{ $t('delete') }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <Card :isFeaturedTask="false" :deliveryTime="task.packages[0].delivery" :carouselImage="task" :username="taskUser.username" :slug="task.slug"
              :user_rating="taskUser.review_average" :user_count="taskUser.review_count" :user_online="false"
              :imageIcon="taskUser.photo_path" :description="task.title" :rating="task.review_average" :count="task.review_count"
              :price="task.packages[0].price" :hash="task.hash" :select="task.favourite" :etn="etnConvert(task.packages[0].price)"
              :saveTasksPage="true" :profilePage="true" :position="index + 1" :sellerInfo="taskUser" location="users-dashboard"
              :triggerTrackingEvent="admin" :primaryCategorySlug="task.categories[0] === undefined ? null : task.categories[0].slug " :isHeroTask="task.hero"
              v-bind:class="{'card-disable': task.slug === null}"
              class="mx-auto my-sm-0 w-100 no-actions"/>
      </div>
    </div>
  </b-tab>
</template>

<script>
import Card from '../Task/Card'
import ApiService from '../../apiService'
import helper from '@/helper'

export default {
  name: 'DashboardTasksTab',
  components: {
    Card
  },
  props: {
    'title': {
      type: String,
      required: true
    },
    'taskUser': {
      type: Object,
      required: true
    },
    'tasks': {
      type: Array,
      required: true
    },
    'dropdown': {
      type: Boolean,
      required: false,
      default: true
    },
    'conversionRate': {
      type: [Number, String],
      required: true
    },
    'admin': {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    formatMobileUrl (filename, filetype) {
      return helper.formatMobileUrl(filename, filetype)
    },
    confirmActivateTask (active, slug) {
      let vm = this
      this.active = active
      this.$swal({
        title: this.$t('sure'),
        text: this.active === 0 ? this.$t('deactivate_warning') : this.$t('activate_warning'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.active === 0 ? this.$t('deactivate_action') : this.$t('activate_action'),
        cancelButtonText: this.$t('discard_action'),
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return new Promise(() => {
            const data = {
              'status': this.active
            }
            ApiService.gigActivation(slug, data).then((response) => {
              this.$swal(this.active === 0 ? this.$t('deactivated') : this.$t('activated'), this.active === 0 ? this.$t('deactivate_confirm') : this.$t('activate_confirm'), 'success').then((result) => {
                vm.$vnode.context.getTasksByUser()
              })
            }).catch((error) => {
              this.$swal.showValidationMessage(
                '<p>' + error.response.data.error_message + '</p>'
              )
              this.$swal.hideLoading()
            })
          })
        }
      })
    },
    confirmDeleteTask (taskHash) {
      this.$swal({
        title: this.$t('sure'),
        text: this.$t('delete_warning'),
        type: this.$t('type_warning'),
        showCancelButton: true,
        confirmButtonText: this.$t('confirm_delete'),
        cancelButtonText: this.$t('do_not_delete'),
        showCloseButton: true
      }).then((result) => {
        if (result.value) {
          this.deleteTask(taskHash)
        }
      })
    },
    deleteTask (hash) {
      let vm = this
      ApiService.deleteTask(hash).then(() => {
        this.$swal(
          this.$t('deleted'),
          this.$t('delete_confirmed'),
          this.$t('type_success')
        ).then(() => {
          vm.$vnode.context.getTasksByUser()
        })
      }).catch(() => { })
    },
    etnConvert (price) {
      return (price / this.conversionRate).toFixed(2)
    }
  }
}
</script>

<style lang="scss">
#dashboard {
  .task-image img {
    border-top-left-radius: 0;
  }
  .delivery-and-price {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-item {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    margin: 10px 0;
  }
  .task-action-bottom-container {
    height: fit-content;
    width: inherit;
  }
  .card {
    display: flex;
    flex-direction: column;
    width: inherit;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .card-disable {
    pointer-events: none;
  }
}
</style>

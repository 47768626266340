<template>
    <div>
        <b-alert v-model="showSuccessAlert" variant="success" dismissible>
            {{ $t('success_alert_roles') }}
        </b-alert>
      <b-alert v-model="showValidation" variant="danger" dismissible>
        {{validationMessage}}
      </b-alert>
        <div class="container" v-if="!loading">
            <div class="col-xl-12">
                <div class="row row-featured row-featured-category">
                    <div class="col-xl-12 ">
                        <div class="inner">
                          <h2>{{$t('user_roles')}}</h2>
                        </div>
                    </div>
                    <div class="container mb-3 mt-3">
                      <b-form-checkbox-group id="roles" class="text-left ml-5" v-model="roles_data">
                        <table class="table table-striped table-bordered add-manage-table table demo footable-loaded footable"
                               data-filter="#filter" data-filter-text-only="true">
                          <thead>
                            <tr>
                              <th>{{$t('role_name')}}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(role, index) in roles" :key="index">
                              <td data-title="name">
                                {{role.name.replace(/_/g, " ")}}
                              </td>
                              <td data-title="button" class="text-center">
                                <b-form-checkbox  :key="index" class="pl-4 ml-2 largerCheckbox" :value="role.hash" size="lg" switch  />
                              </td>
                          </tr>
                          </tbody>
                        </table>
                      </b-form-checkbox-group>
                      <b-button class="w-50 mt-3" v-on:click="handleSubmit" variant="primary">Submit Roles</b-button>
                    </div>
                </div>
            </div>
        </div>
      <div v-else>
        <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner"></b-spinner>
        <div class="mb-5">{{$t('loading_users')}}</div>
      </div>
    </div>
</template>

<script>
import ApiService from '../../apiService.js'
export default {
  name: 'RolesForm',
  props: {
    userRoles: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    hash: {
      type: String,
      required: true,
      default: ''
    }
  },
  data () {
    return {
      username_data: this.username,
      roles_data: [],
      showSuccessAlert: false,
      roles: '',
      loading: true,
      showValidation: false,
      validationMessage: ''
    }
  },
  mounted () {
    this.getRoles()
  },
  methods: {
    getRoles () {
      ApiService.getRolesAdmin().then((response) => {
        this.roles = response.data.data
        this.loading = false
        this.extractRoles()
      })
    },
    extractRoles () {
      let hash = []
      const uRoles = this.userRoles
      this.roles.forEach(function (item) {
        if (uRoles.includes(item.name)) {
          hash.push(item.hash)
        }
      })
      this.roles_data = hash
    },
    handleSubmit () {
      this.showSuccessAlert = false
      this.showValidation = false
      this.loading = true
      let roles = {
        'roles': this.roles_data
      }

      ApiService.postRoles(this.hash, roles).then((response) => {
        this.showSuccessAlert = true
        this.loading = false
      }).catch(error => {
        this.validationMessage = error.response.data.errors.children.roles.errors[0]
        this.showValidation = true
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
  .largerCheckbox {
    transform : scale(2);
  }
</style>

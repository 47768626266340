<template>
  <div>
    <table class="table table-striped table-bordered add-manage-table" v-if="user.user_balance.length > 0">
      <thead>
      <tr>
        <th>Amount</th>
        <th>Date Expired</th>
        <th v-if="hasAccess"/>
      </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in getUserBalance" :key="index" class="mb-md-0 mb-3" :class="hasExpired(item.date_expired) ? 'text-danger' : 'text-success'">
          <td>${{ item.amount.toFixed(2) }}</td>
          <td>{{ new Date(item.date_expired).toLocaleDateString() }}</td>
          <td class="w-25" v-if="hasAccess">
            <b-button size="sm" class="py-2 ml-3" variant="primary" @click="setBalance(item); showBalanceModal();">
            Edit Balance
          </b-button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="alert alert-warning">
      This user does not have any gift cards assigned to them
    </div>

    <AdminUserBalance :userHash="user.hash" :userBalance="userBalance" />
  </div>
</template>

<script>
import AdminUserBalance from '../Users/AdminUserBalance'

export default {
  name: 'AdminGiftCardsTab',
  components: {
    AdminUserBalance
  },
  props: {
    user: Object
  },
  data () {
    return {
      hasAccess: this.$store.getters.hasRole('ROLE_ADMIN_USER_BALANCE'),
      userBalance: {
        hash: null,
        amount: 0,
        date_expired: '2022-06-01 10:00:00'
      }
    }
  },
  methods: {
    showBalanceModal () {
      this.$bvModal.show('modal_balance')
    },
    setBalance (item) {
      this.userBalance = item
    },
    hasExpired (date) {
      return (new Date()) >= (new Date(date))
    }
  },
  computed: {
    getUserBalance () {
      let balance = this.user.user_balance
      return balance.reverse()
    }
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-5 col-lg-4">
        <Profile :username="user.username" :verify="user.verification_type" :rating="user.review_average" :reviews="user.review_count"
                 :shortDescription="user.summary" :buttons="false" :image_path="user.photo_path" :hash="user.hash" :admin="true" popover_id="userProfileAdmin"/>
      </div>
      <div class="col-sm-12 col-md-5 col-lg-8">
        <template v-if="activeStatus === 'Closed'">
          <div class="alert alert-danger" role="alert">
            <template v-if="lockedReason !== null">
              <i class="fa-solid fa-lock"/> This account was closed on {{ date(user.date_deleted) }}, due to the following reason:
              <p class="m-0">{{ lockedReason }}</p>
            </template>
            <template v-else>
              <i class="fa-solid fa-lock"/> This account was closed on {{ date(user.date_deleted) }}, reason was not required at the time.
            </template>
          </div>
        </template>
        <template v-if="activeStatus === 'Locked'">
          <div class="alert alert-danger" role="alert">
            <template v-if="banReason !== null">
              <i class="fa-solid fa-lock"/> This account was locked, due to the following reason:
              <p class="m-0">{{ banReason }}</p>
            </template>
            <template v-else>
              <i class="fa-solid fa-lock"/> This account was locked before reason was required.
            </template>
          </div>
        </template>
        <div class="info-box-simple mt-0 mb-2">
          <b-card>
            <b-card-text class="info">
              <div class=" box-title border-bottom border-colour mb-3">
                <div class="inner">
                  <h2 class="mb-0 mt-0">User Data</h2>
                </div>
              </div>
              <div>
                <ul class="fa-ul mb-0 pb-0 pl-0" style="--fa-li-margin: 1.4em;">
                  <li class="d-flex justify-content-between mb-1"><span class="fa-li"><i class="fa-solid fa-id-badge"></i></span>Full name: <strong>{{ user.name }}</strong></li>
                  <li class="d-flex justify-content-between mb-1"><span class="fa-li"><i class="fa-solid fa-envelope"></i></span>Email: <strong>{{ user.email }}</strong></li>
                  <li class="d-flex justify-content-between mb-1"><span class="fa-li"><i class="fa-solid fa-link"></i></span>SSO ID: <strong>{{ user.electroneum_id }}</strong></li>
                  <li class="d-flex justify-content-between mb-1"><span class="fa-li"><i class="fa-solid fa-location-dot"></i></span>From:
                    <strong>{{ user.country_iso ? $t('country.' + user.country_iso) : 'N/A' }}</strong></li>
                  <li class="d-flex justify-content-between mb-1"><span class="fa-li"><i class="fa-solid fa-user-plus"></i></span>Member since: <strong>{{ joinDate() }}</strong>
                  </li>
                  <li class="d-flex justify-content-between mb-1"><span class="fa-li"><i class="fa-solid fa-unlock"></i></span>Account status: <strong>{{ activeStatus }}</strong>
                  </li>
                  <li class="d-flex justify-content-between mb-1"><span class="fa-li"><i class="fa-solid fa-user-clock"></i></span>Last active:
                    <strong>{{ (new Date(user.last_active)).toLocaleString() }}</strong></li>
                  <li class="d-flex justify-content-between mb-1"><span class="fa-li"><i class="fa-solid fa-calendar-days"></i></span>Away status: <strong>{{
                      awayMessage
                    }}</strong></li>
                  <li class="d-flex justify-content-between mb-1"><span class="fa-li"><i class="fa-solid fa-envelope-circle-check"></i></span>Email Confirmed:
                    <strong>{{ user.email_confirmed }}</strong></li>
                  <li class="d-flex justify-content-between mb-1"><span class="fa-li"><i class="fa-solid fa-eye-slash"></i></span>Shadow banned: <strong>{{
                      user.shadow_ban
                    }}</strong></li>
                  <li class="d-flex justify-content-between"><span class="fa-li"><i class="fa-solid fa-usd"></i></span>Balance: <strong>{{ getTotalBalance }}</strong></li>
                </ul>
              </div>
            </b-card-text>
          </b-card>
        </div>
        <InfoBoxSimple v-if="user.languages.length > 0" class="mb-3 profile-languages" :heading="$t('user_language')" type="list" :content="user.languages"/>
      </div>
    </div>

    <div class="text-center mt-4" v-if="activeStatus !== 'Closed'">
      <b-button size="sm" type="submit" class="py-2 ml-3" variant="outline-primary" @click="setAway(user.hash)" v-if="this.$store.getters.hasRole('ROLE_ADMIN_AWAY')"
                v-bind:class="{'disabled': user.away}">
        Set Away
      </b-button>
      <b-button size="sm" type="submit" class="py-2 ml-3" variant="success" v-on:click="watchOrderAccount(user.hash, user.watched)"
                v-if="this.$store.getters.hasRole('ROLE_ADMIN_WATCHED')">
        {{ user.watched !== true ? 'Watch orders' : 'Unwatch orders' }}
      </b-button>
      <b-button size="sm" type="submit" class="py-2 ml-3" variant="outline-success" v-on:click="watchUserAccount(user.watched_list !== null ? user.watched_list.hash : user.hash , user.watched_list !== null)"
                v-if="this.$store.getters.hasRole('ROLE_ADMIN_WATCHED')">
        {{ user.watched_list === null ? 'Watch user' : 'Unwatch user' }}
      </b-button>
      <b-button size="sm" type="submit" class="py-2 ml-3" variant="info" v-if="this.$store.getters.hasRole('ROLE_ADMIN_PAUSE_SELLER')"
                v-on:click="lockUserAccount(user.locked, user.hash)">
        {{ user.locked !== true ? 'Lock account' : 'Unlock account' }}
      </b-button>
      <b-button size="sm" type="submit" class="py-2 ml-3" variant="secondary" v-on:click="pauseUserAccount(user.verification_type, user.hash)"
                v-if="this.$store.getters.hasRole('ROLE_ADMIN_PAUSE_SELLER')">
        {{ user.verification_type !== 2 ? 'Pause account' : 'Unpause account' }}
      </b-button>
      <b-button size="sm" type="submit" class="py-2 ml-3" variant="warning" v-on:click="shadowBan(user.hash, user.shadow_ban)" v-if="roleCheckBan">
        {{ !user.shadow_ban ? 'Apply shadow ban' : 'Remove shadow ban' }}
      </b-button>
      <b-button size="sm" type="submit" class="py-2 ml-3" variant="danger" v-on:click="closeUserAccount()" v-if="this.$store.getters.hasRole('ROLE_ADMIN_USER_DEACTIVATE')">
        Close Account
      </b-button>
    </div>
  </div>
</template>

<script>
import Profile from '../../MyAccount/UserProfile'
import InfoBoxSimple from '../../Task/InfoBoxSimple'
import ApiService from '../../../apiService'

export default {
  name: 'AdminUserProfile',
  components: {
    Profile,
    InfoBoxSimple
  },
  props: {
    user: Object,
    lockedReason: String,
    banReason: String
  },
  data () {
    return {
      activeStatus: this.checkActiveStatus(),
      roleCheckBan: this.$store.getters.hasRole('ROLE_ADMIN_SHADOW_BAN'),
      awayMessage: this.createAwayMessage()
    }
  },
  methods: {
    showBalanceModal () {
      this.$bvModal.show('modal_balance')
    },
    watchOrderAccount (hash, watch) {
      let vm = this
      this.$swal({
        title: watch === false ? 'Watch orders' : 'Unwatch orders',
        text: watch === false ? 'This will watch the user\'s orders and all orders on completion will go through delayed payments.' : 'This will unwatch user orders and their orders will no longer automatically go into the delayed payments',
        type: 'warning',
        input: 'textarea',
        inputPlaceholder: watch === false ? 'Please type your reason here for watching the user orders' : 'Please type your reason here for no longer watching users orders',
        inputValidator: (value) => {
          if (!value) {
            return 'Please enter a reason'
          }
        },
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: watch === false ? 'Watch orders' : 'Unwatch orders',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: (result) => {
          if (result !== '') {
            return ApiService.adminPatchUser(hash, { watched_reason: result, watched: watch === false ? 1 : 0 }).then((response) => {
            }).catch((error) => {
              this.$swal.showValidationMessage(
                error.response.data.error_message
              )
            })
          }
        }
      }).then((result) => {
        if (result.value !== '' && result.dismiss == null) {
          this.$swal({
            title: 'Success',
            text: watch === false ? 'Users account is being watched' : 'Users account is no longer being watched',
            type: 'success',
            confirmButtonText: 'Close'
          }).then(() => {
            vm.$vnode.context.getUsers()
          })
        }
      })
    },
    watchUserAccount (hash, watch) {
      let vm = this
      this.$swal({
        title: watch === false ? 'Watch users account' : 'Unwatch users account',
        text: watch === false ? 'This will watch the user\'s account.' : 'This will unwatch user account',
        type: 'warning',
        input: 'textarea',
        inputPlaceholder: watch === false ? 'Please type your reason here for watching the user account' : 'Please type your reason here for no longer watching users account',
        inputValidator: (value) => {
          if (!value) {
            return 'Please enter a reason'
          }
        },
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: watch === false ? 'Watch account' : 'Unwatch account',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: async (result) => {
          if (result !== '') {
            if (watch === true) {
              await ApiService.postAdminWatchedListNote(hash, result).then(() => {})
              return ApiService.removeAdminWatchedList(hash).then(() => {
              }).catch((error) => {
                this.$swal.showValidationMessage(
                  error.response.data.error_message
                )
              })
            } else {
              return ApiService.postAdminWatchedList(hash, result).then((response) => {
              }).catch((error) => {
                this.$swal.showValidationMessage(
                  error.response.data.error_message
                )
              })
            }
          }
        }
      }).then((result) => {
        if (result.value !== '' && result.dismiss == null) {
          this.$swal({
            title: 'Success',
            text: watch === false ? 'Users account is being watched' : 'Users account is no longer being watched',
            type: 'success',
            confirmButtonText: 'Close'
          }).then(() => {
            vm.$vnode.context.getUsers()
          })
        }
      })
    },
    shadowBan (hash, value) {
      let vm = this
      this.$swal({
        title: !value ? 'Applying shadow ban' : 'Removing shadow ban',
        text: !value ? 'This will apply shadow ban and task will no longer appear in search.' : 'This will remove shadow ban and user will appear in search',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: (result) => {
          if (result === true) {
            return ApiService.shadowBanUser(hash, value).then(() => {
            }).catch((error) => {
              this.$swal.showValidationMessage(
                error.response.data.error_message
              )
            })
          }
        }
      }).then((result) => {
        if (result.value === true) {
          this.$swal({
            title: 'Success',
            text: !value ? 'Shadow ban has been applied' : 'Shadow ban has been removed',
            type: 'success',
            confirmButtonText: 'Close'
          }).then(() => {
            vm.$vnode.context.getUsers()
          })
        }
      })
    },
    checkActiveStatus () {
      if (this.user.date_deleted !== null) {
        return 'Closed'
      } else if (this.user.locked) {
        return 'Locked'
      } else {
        return 'Active'
      }
    },
    createAwayMessage () {
      if (this.user.away) {
        return this.user.returning_date ? 'Away (' + (new Date(this.user.returning_date.toLocaleString())).toLocaleDateString() + ')' : 'Away (N/A)'
      }
      return 'Active'
    },
    joinDate () {
      let date = new Date(this.user.member_since)
      return (this.$t('month.' + (date.getMonth() + 1))) + ' ' + date.getFullYear()
    },
    pauseUserAccount (verificationType, hash) {
      let vm = this
      this.$swal({
        title: verificationType !== 2 ? 'Pause users account' : 'Unpause users account',
        text: verificationType !== 2 ? 'This will pause users account and they will no longer be able to sell' : 'This will unpause users account and they can start selling again',
        type: 'warning',
        input: 'textarea',
        inputPlaceholder: verificationType !== 2 ? 'Please type your reason here for pausing account' : 'Please type your reason here for unpausing account',
        inputValidator: (value) => {
          if (!value) {
            return 'Please enter a reason'
          }
        },
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: verificationType !== 2 ? 'Pause account' : 'Unpause account',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: (result) => {
          if (result !== '') {
            return ApiService.adminPatchUser(hash, { pause_reason: result, pause: verificationType !== 2 }).then((response) => {
            }).catch((error) => {
              this.$swal.showValidationMessage(
                error.response.data.error_message
              )
            })
          }
        }
      }).then((result) => {
        if (result.value !== '' && result.dismiss == null) {
          this.$swal({
            title: 'Success',
            text: verificationType !== 2 ? 'Users account has been paused' : 'Users account has been unpaused',
            type: 'success',
            confirmButtonText: 'Close'
          }).then(() => {
            vm.$vnode.context.getUsers()
          })
        }
      })
    },
    closeUserAccount () {
      let vm = this
      this.$swal({
        title: 'Closing ' + this.user.email + ' account',
        text: 'This will delete any tasks, cancel any open orders and prevent the user from logging in.',
        type: 'warning',
        input: 'textarea',
        inputPlaceholder: 'Please type your reason here for closing this account',
        inputValidator: (value) => {
          if (!value) {
            return 'Please enter a reason'
          }
        },
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: (result) => {
          if (result !== '') {
            return ApiService.closeAccount(this.user.hash, result).then(() => {
            }).catch((error) => {
              this.$swal.showValidationMessage(
                error.response.data.error_message
              )
            })
          }
        }
      }).then((result) => {
        if (result.value !== '' && result.dismiss == null) {
          this.$swal({
            title: 'Success',
            text: this.user.email + ' account has been closed',
            type: 'success',
            confirmButtonText: 'Close'
          }).then(() => {
            vm.$vnode.context.getUsers()
          })
        }
      })
    },
    date (date) {
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    },
    lockUserAccount (lock, hash) {
      let vm = this
      this.$swal({
        title: lock === false ? 'Lock users account' : 'Unlock users account',
        text: lock === false ? 'This will lock users account and they will no longer be able to access their account' : 'This will unlock users account and they can start using their account again',
        type: 'warning',
        input: 'textarea',
        inputPlaceholder: lock === false ? 'Please type your reason here for locking account' : 'Please type your reason here for unlocking account',
        inputValidator: (value) => {
          if (!value) {
            return 'Please enter a reason'
          }
        },
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: lock === false ? 'Lock account' : 'Unlock account',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: (result) => {
          if (result !== '') {
            return ApiService.adminPatchUser(hash, { reason: result, lock: lock === false ? 1 : 0 }).then((response) => {
            }).catch((error) => {
              this.$swal.showValidationMessage(
                error.response.data.error_message
              )
            })
          }
        }
      }).then((result) => {
        if (result.value !== '' && result.dismiss == null) {
          this.$swal({
            title: 'Success',
            text: lock === false ? 'Users account has been locked' : 'Users account has been unlocked',
            type: 'success',
            confirmButtonText: 'Close'
          }).then(() => {
            vm.$vnode.context.getUsers()
          })
        }
      })
    },
    setAway (hash) {
      if (this.user.away) {
        return
      }

      const vm = this
      this.$swal({
        title: 'Set user away',
        text: 'This will set the user away and they will get an email',
        type: 'warning',
        input: 'textarea',
        inputPlaceholder: 'Please type your reason here for setting the user away',
        inputValidator: (value) => {
          if (!value) {
            return 'Please enter a reason'
          }
        },
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Set away',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: (result) => {
          if (result !== '') {
            return ApiService.adminPatchUser(hash, { away_reason: result, away: 1 }).then((response) => {
            }).catch((error) => {
              this.$swal.showValidationMessage(
                error.response.data.error_message
              )
            })
          }
        }
      }).then((result) => {
        if (result.value !== '' && result.dismiss == null) {
          this.$swal({
            title: 'Success',
            text: 'User was set away successfully',
            type: 'success',
            confirmButtonText: 'Close'
          }).then(() => {
            vm.$vnode.context.getUsers()
          })
        }
      })
    }
  },
  computed: {
    getTotalBalance: function () {
      const today = new Date()
      let total = 0
      if (this.user.user_balance.length) {
        this.user.user_balance.forEach(function (item) {
          if (today <= (new Date(item.date_expired))) {
            total += item.amount
          }
        })
        if (total > 0) {
          return '$' + total.toFixed(2)
        }
      }
      return 'N/A'
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-secondary {
  background-color: $brand-denim !important;
  border-color: $brand-denim !important;
}

.box-title h2 {
  text-transform: none;
  font-size: 18px;
}

ul {
  list-style-type: none;
  font-size: 14px;
  text-align: left;
}

.border-colour {
  border-color: #ddd;
}
</style>

<template>
  <div id="messageAdmin" class="container mb-3 mt-0 content-box">
    <div class="row p-2" :class="{'message-box-container' : messages.length === 0}">
      <div class="col-lg-4 col-12 p-0" v-bind:class="{'hidden-sm' : !smallScreenView}">
        <div class="mr-0 mr-lg-3 message-list-column h-100">
          <div class="list-group-item mb-0 border-0" style="cursor: default">
            <h4 class="font-weight-bold m-0">Inbox</h4>
          </div>
          <div v-if="!inboxLoaded" class="mx-auto my-5 d-lg-none d-xl-none">
            <b-spinner large label="Large Spinner"/>
            <div>Loading inbox</div>
          </div>
          <div class="message-list" v-else-if="inboxLoaded">
            <div id="home" class="message-chat-history-list" @scroll="onScroll">
              <div class="list-group border-0 " v-for="(message, index) in inbox" :key="index" :id="'message-chat-inbox-' + message.hash">
                <div v-lazy-container="{ selector: 'img' }" class="list-group-item py-3 px-2 pr-3 border-0 message-container d-flex h-100" :id="'messageSeen' + index"
                     @click="lastActive.to = message.to_last_active; lastActive.from = message.from_last_active; getMessage(message.hash, 'messageSeen' + index, message.from_username, message.to_username )"
                     :class="{ 'message-active': active.inbox === 'messageSeen' + index }">
                  <inbox-item :message="message" :current-user="username" :withinLastSeconds="withinLastSeconds" :toLastActive="message.to_last_active"
                              :fromLastActive="message.from_last_active" :ref="'inboxItem' + index" :hideUnread="true"/>
                </div>
              </div>
              <div v-if="pagination.perPage < pagination.total" class="w-100 my-4">
                <div class="d-inline-flex justify-content-center load-more p-2">
                  <b-spinner class="mr-2" small label="Small Spinner"/>
                  <span>Loading more - ({{ pagination.total }}&nbsp;Total)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="message-container" class="col-sm-12 col-md-12 col-lg-8 px-0" v-bind:class="{'hidden-sm' : smallScreenView}">
        <!-- chat -->
        <div v-if="messages.length > 0" class="h-100">
          <transition name="fade">
            <MessageInbox :toLastActiveDate="lastActive.to" :fromLastActiveDate="lastActive.from" :withinLastSeconds="withinLastSeconds" :messages="messages" device="device"
                          :unreadMessage="active.unreadMessages" :title="taskTitle" v-if="messageLoaded" :displayInbox="displayInbox" :hash="active.hash" :adminUsernameCheck="username"
                          :activeMessage="active.message" :lock="true" :customDetail="active.customDetail" :fromUsername="fromUsername" :toUsername="toUsername" :admin="true"/>
          </transition>
          <div v-if="!messageLoaded" class="d-flex justify-content-center align-items-center h-100">
            <div class="d-flex flex-column align-items-center">
              <b-spinner large label="Large Spinner" class="mb-2"/>
              <div>Message Loading</div>
            </div>
          </div>
        </div>
        <div v-if="!inboxLoaded" class="d-flex justify-content-center align-items-center h-100">
          <div class="d-flex flex-column align-items-center py-5">
            <b-spinner large label="Large Spinner"/>
            <div>Message loading</div>
          </div>
        </div>
        <div v-if="inboxLoaded && messageLoaded && messages.length === 0" class="row h-100">
          <div class="mx-auto no-messages flex-col justify-content-center align-items-center">
            <h3>User currently has no messages</h3>
            <i class="mb-3 fa-regular fa-comments fa-4x" aria-hidden="true"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../../apiService'
import InboxItem from '../../Message/InboxItem'
import MessageInbox from '../../MyAccount/Inbox/MessageInbox'

export default {
  name: 'AdminUserMessageTab',
  props: {
    username: String,
    userHash: String,
    loadMessages: Boolean
  },
  data () {
    return {
      messages: [],
      smallScreenView: true,
      inbox: [],
      inboxLoaded: false,
      fromUsername: '',
      toUsername: '',
      active: {
        hash: '',
        inbox: '',
        message: '',
        customDetail: {},
        lock: '',
        unreadMessages: []
      },
      messageLoaded: false,
      lastActive: {
        to: null,
        from: null
      },
      loadMoreCount: 1,
      pagination: {
        perPage: 6,
        total: 0,
        page: 1
      },
      withinLastSeconds: 1000 * 60 * 5,
      taskTitle: ''
    }
  },
  components: {
    InboxItem,
    MessageInbox
  },
  watch: {
    'loadMessages': function (val) {
      if (val === true) {
        this.getInbox(this.pagination.page, this.pagination.perPage)
      }
    }
  },
  mounted () {
    if (this.loadMessages) {
      this.getInbox(this.pagination.page, this.pagination.perPage)
    }
  },
  methods: {
    getInbox (page, perPage, getMessage = true) {
      let displayView = window.getComputedStyle(document.getElementById('message-container'), 'style').display !== 'none'

      ApiService.getInboxAdmin(page, perPage, this.userHash).then(response => {
        this.inbox = response.data.data.results
        this.pagination.total = response.data.data.pagination.total
        this.pagination.perPage = response.data.data.pagination.per_page
        if (this.inbox.length > 0 && displayView && getMessage) {
          this.getMessage(this.inbox[0].hash, 'messageSeen0', this.inbox[0].from_username, this.inbox[0].to_username)
          this.activeInbox = 'messageSeen0'
          this.lastActive.from = this.inbox[0].from_last_active
          this.lastActive.to = this.inbox[0].to_last_active
        } else {
          this.inboxLoaded = true
          this.messageLoaded = true
        }
      })
    },
    getMessage (hash, id, fromUsername, toUsername) {
      this.messageLoaded = false
      ApiService.getMessages(hash).then(response => {
        this.messages = response.data.data
        this.taskTitle = this.inbox.title
        this.active.hash = hash
        this.active.inbox = id
        this.active.message = id
        this.active.unreadMessages = this.messages.filter(function (message) {
          return message.read < 1
        })
        this.fromUsername = fromUsername
        this.toUsername = toUsername
        this.customDetail(this.findInboxes([hash])[0])
        this.smallScreenView = false
        this.inboxLoaded = true
        this.messageLoaded = true
      })
    },
    customDetail (inbox) {
      this.active.customDetail = {
        'status': inbox.custom_status,
        'info': inbox.additional_information,
        'delivery': inbox.custom_task_delivery,
        'hash': inbox.custom_task_hash,
        'revisions': inbox.custom_task_revisions,
        'slug': inbox.custom_task_slug,
        'price': inbox.custom_task_price,
        'quote_owner': inbox.from_username,
        'task_owner': inbox.to_username,
        'author_photo': inbox.from_photo
      }
    },
    findInboxes (hashes) {
      return this.inbox.filter(function (inbox) {
        return hashes.indexOf(inbox.hash) !== -1
      })
    },
    displayInbox () {
      this.smallScreenView = true
    },
    onScroll ({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (this.pagination.perPage < this.pagination.total && Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
        this.loadMoreCount += 1
        this.getInbox(this.pagination.page, 6 * this.loadMoreCount, false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin sm-plus10-down {
  @media (max-width: #{$breakpoint-sm+10}) {
    @content;
  }
}

$grey-border-and-text: #e1e1e1;
$grey-bg: #f5f5f5;
$grey-dark-border: #c4c2c2;
$grey-dark-text: #7c7c7d;

.load-more {
  border: 1px solid $grey-border-and-text;
  border-radius: 5px;
}

@include sm-plus10-down {
  .message-list .list-group {
    height: auto;
  }
}

::v-deep .new-message-icon {
  background: $brand-sky;
  top: -2px;
  right: -2px;
  padding: 0px 4px;
  border-radius: 20px;
  font-size: 10px;
  color: white;
}

.content-box {
  background: transparent;
  box-shadow: none;
}

.list-group-item {
  border-radius: unset !important;
  border-bottom: 1px solid $grey-border-and-text !important;
  background: transparent;
  cursor: pointer;
}

.message-chat-history .list-group-item:hover {
  box-shadow: none;
}

.message-active,
.message-container:hover {
  background-color: $grey-bg;
}

#message-container {
  border: 1px solid #ddd;
  border-radius: 7px;
  max-height: 585px;
  background: white;
}

::v-deep .round {
  border-radius: 50% !important;
}

.message-list-column {
  background: white;
  border-radius: 7px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.0675);
}

::v-deep .message-list {
  font-size: 12px;
  color: $grey-dark-text;

  .message-list-hash {
    color: $grey-dark-text;
  }

  .message-list-user {
    font-size: 1rem;
    color: $brand-denim;
  }
}

::v-deep .message-list-content {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.no-messages {
  color: lightslategray;
}

.show {
  display: none;
}

.message-container {
  border-left: 2px solid transparent !important;
}

.message-container.not-seen {
  border-left: 2px solid $brand-tangerine !important;
}

.message-active {
  border-left: 2px solid #44aae1 !important;
}

.message-container ::v-deep .list-box-content .title {
  font-weight: normal !important;
}

.message-box-container {
  height: 58.5vh !important;
}
</style>
<style lang="scss">
#messageAdmin {
  .fade-enter-active, .fade-leave-active {
    transition: opacity .25s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }

  .avatar-fallback {
    background: $grey-dark-text;
    font-size: 1.25rem;
  }

  .message-list .list-group-item {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;
  }

  .message-list .list-group:hover {
    box-shadow: inset 1px 0 0 #dfdfdf, inset 1px 0 0 #dfdfdf, 0 0 1px 0 rgba(60, 64, 67, 0.1), 0 1px 3px 1px rgba(60, 64, 67, 0.1);
  }

  .message-list .list-group-item .form-check {
    padding-left: 0;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    justify-items: center;
    -webkit-align-self: center;
    align-self: center;
  }

  .message-list .list-group-item .form-check .custom-control {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
  }

  .message-list .list-group-item .list-box-user {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    width: 12%;
  }

  .message-list .list-group-item .list-box-user img {
    padding: 2px;
    border-radius: 2px;
  }

  .message-list .list-group-item .list-box-user .name {
    font-size: 11px;
    font-weight: 500;
    margin-top: 2px;
    text-overflow: ellipsis;
    width: 110%;
    white-space: nowrap;
    overflow: hidden;
  }

  .message-list .list-group-item .list-box-user .name i {
    font-size: 10px;
    margin-right: 3px;
  }

  .message-list .list-group-item .list-box-user .name i.online {
    color: #2ECC71;
  }

  .message-list .list-group-item .list-box-user .name i.offline {
    color: #636c72;
  }

  .message-list .list-group-item .list-box-content {
    /* width: 75%; */
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    -webkit-font-smoothing: antialiased;
  }

  .message-list .list-group-item .list-box-content .title {
    font-weight: 700;
    color: #292b2c;
    font-size: .875rem;
    letter-spacing: .2px;
    text-overflow: ellipsis;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
  }

  .message-list .list-group-item .list-box-content .message-text {
    color: #292b2c;
    font-size: 12px;
    max-width: 80%;
    font-weight: bold;
  }

  .message-list .list-group-item .list-box-content .time {
    font-size: 11px;
    color: #636c72;
    margin-top: 5px;
  }

  .message-list .list-group-item .list-box-action {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    -webkit-justify-content: space-evenly;
    -moz-box-pack: space-evenly;
    justify-content: space-evenly;
  }

  .message-list .list-group-item .list-box-action a {
    color: #292b2c;
  }

  .message-list .list-group-item .list-box-action a:hover {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

  .message-list .list-group-item.seen .list-box-content .title {
    font-weight: normal;
  }

  .message-list .list-group-item.seen .list-box-content .message-text {
    font-weight: normal;
  }

  .message-chat-history, .message-chat-history-list {
    display: block;
    overflow-y: auto;
  }

  .message-chat-history {
    margin-bottom: auto;
    flex: 1;
  }

  .message-chat-history-list {
    max-height: 508px;

    .pagination {
      .page-item {
        padding: 0;
      }
    }
  }

  .message-chat {
    max-height: 580px;
  }

  .list-box-avatar img, .avatar-fallback {
    min-height: 42px;
    min-width: 42px;
    height: 42px;
    width: 42px;
    border-radius: 50%;
    overflow: hidden;
    padding: 0;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  }
}
</style>

<template>
<div>
  <b-tabs card v-if="loaded">
      <b-tab :title="$t('all')" active>
          <AdminOrdersTable v-if="loadedOrders" :orders="orders" type="all" :userType="type"/>
      </b-tab>
    <b-tab :title="$t('awaiting_acceptance')" v-on:click="getOrdersPending(1,0)">
      <AdminOrdersTable v-if="loadedPending" :orders="ordersPending" type="acceptance" :userType="type"/>
      <div v-else class="mx-auto my-5">
        <b-spinner large label="Large Spinner"></b-spinner>
        <div>{{ $t('loading_orders') }}</div>
      </div>
    </b-tab>
    <b-tab :title="$t('in_progress')" v-on:click="getOrdersProgress(1,3)">
      <AdminOrdersTable v-if="loadedProgress" :orders="ordersProgress" type="progress" :userType="type"/>
      <div v-else class="mx-auto my-5">
        <b-spinner large label="Large Spinner"></b-spinner>
        <div>{{ $t('loading_orders') }}</div>
      </div>
    </b-tab>
    <b-tab :title="$t('delivered')" v-on:click="getOrdersDelivered(1,4)">
      <AdminOrdersTable v-if="loadedDelivered" :orders="ordersDelivered" type="delivered" :userType="type"/>
      <div v-else class="mx-auto my-5">
        <b-spinner large label="Large Spinner"></b-spinner>
        <div>{{ $t('loading_orders') }}</div>
      </div>
    </b-tab>
    <b-tab :title="$t('completed')" v-on:click="getOrdersCompleted(1,5)">
      <AdminOrdersTable v-if="loadedCompleted" :orders="ordersCompleted" type="completed" :userType="type"/>
      <div v-else class="mx-auto my-5">
        <b-spinner large label="Large Spinner"></b-spinner>
        <div>{{ $t('loading_orders') }}</div>
      </div>
    </b-tab>
    <b-tab :title="$t('declined')" v-on:click="getOrdersDeclined(1,2)">
      <AdminOrdersTable v-if="loadedDeclined" :orders="ordersDeclined" type="declined" :userType="type"/>
      <div v-else class="mx-auto my-5">
        <b-spinner large label="Large Spinner"></b-spinner>
        <div>{{ $t('loading_orders') }}</div>
      </div>
    </b-tab>
    <b-tab :title="$t('disputed')" v-on:click="getOrdersDisputed(1,7)">
      <AdminOrdersTable v-if="loadedDisputed" :orders="ordersDisputed" type="disputed" :userType="type"/>
      <div v-else class="mx-auto my-5">
        <b-spinner large label="Large Spinner"></b-spinner>
        <div>{{ $t('loading_orders') }}</div>
      </div>
    </b-tab>
    <b-tab :title="$t('cancelled')" v-on:click="getOrdersCancelled(1,8)">
      <AdminOrdersTable v-if="loadedCancelled" :orders="ordersCancelled" type="cancelled" :userType="type"/>
      <div v-else class="mx-auto my-5">
        <b-spinner large label="Large Spinner"></b-spinner>
        <div>{{ $t('loading_orders') }}</div>
      </div>
    </b-tab>
    <b-tab title="Expired" v-on:click="getOrdersExpired(1,9)">
      <AdminOrdersTable v-if="loadExpired" :orders="ordersExpired" type="expired" :userType="type"/>
      <div v-else class="mx-auto my-5">
        <b-spinner large label="Large Spinner"></b-spinner>
        <div>{{ $t('loading_orders') }}</div>
      </div>
    </b-tab>
    <b-tab title="Duplicate" v-on:click="getOrdersDuplicate(1,10)">
      <AdminOrdersTable v-if="loadedDuplicate" :orders="ordersDuplicate" type="duplicate" :userType="type"/>
      <div v-else class="mx-auto my-5">
        <b-spinner large label="Large Spinner"></b-spinner>
        <div>{{ $t('loading_orders') }}</div>
      </div>
    </b-tab>
  </b-tabs>
  <div v-else class="mx-auto my-5">
    <b-spinner large label="Large Spinner"></b-spinner>
    <div>{{ $t('loading_orders') }}</div>
  </div>
</div>
</template>

<script>
import ApiService from '../../../apiService.js'
import AdminOrdersTable from '../AdminOrdersTable'
export default {
  name: 'AdminViewOrdersTab',
  components: { AdminOrdersTable },
  props: {
    type: {
      type: String,
      required: true
    },
    userHash: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.getOrders()
  },
  data () {
    return {
      userType: this.type,
      orders: [],
      ordersPending: [],
      loaded: false,
      loadedPending: false,
      loadedOrders: false,
      ordersProgress: [],
      loadedProgress: false,
      ordersDeclined: [],
      loadedDeclined: false,
      ordersDelivered: [],
      loadedDelivered: false,
      ordersCompleted: [],
      loadedCompleted: false,
      ordersDisputed: [],
      loadedDisputed: false,
      ordersCancelled: [],
      loadedCancelled: false,
      ordersDuplicate: false,
      loadedDuplicate: false,
      loadExpired: false,
      ordersExpired: false
    }
  },
  methods: {
    getOrders (page) {
      this.loaded = false
      this.loadedOrders = false
      ApiService.getOrders(page, this.type, 6, this.userHash).then(response => {
        this.orders = response.data.data
        this.loadedOrders = true
        this.loaded = true
      }).catch(() => {
      })
    },
    getOrdersPending (page, status = 0) {
      this.loadedPending = false
      ApiService.getOrdersByStatus(page, this.type, status, 6, this.userHash).then(response => {
        this.ordersPending = response.data.data
        this.loadedPending = true
      }).catch(() => {
      })
    },
    getOrdersProgress (page, status = 3) {
      this.loadedProgress = false
      ApiService.getOrdersByStatus(page, this.type, status, 6, this.userHash).then(response => {
        this.ordersProgress = response.data.data
        this.loadedProgress = true
      }).catch(() => {
      })
    },
    getOrdersDeclined (page, status = 2) {
      this.loadedProgress = false
      ApiService.getOrdersByStatus(page, this.type, status, 6, this.userHash).then(response => {
        this.ordersDeclined = response.data.data
        this.loadedDeclined = true
      }).catch(() => {
      })
    },
    getOrdersDelivered (page, status = 4) {
      this.loadedProgress = false
      ApiService.getOrdersByStatus(page, this.type, status, 6, this.userHash).then(response => {
        this.ordersDelivered = response.data.data
        this.loadedDelivered = true
      }).catch(() => {
      })
    },
    getOrdersCompleted (page, status = 5) {
      this.loadedProgress = false
      ApiService.getOrdersByStatus(page, this.type, status, 6, this.userHash).then(response => {
        this.ordersCompleted = response.data.data
        this.loadedCompleted = true
      }).catch(() => {
      })
    },
    getOrdersDisputed (page, status = 7) {
      this.loadedProgress = false
      ApiService.getOrdersByStatus(page, this.type, status, 6, this.userHash).then(response => {
        this.ordersDisputed = response.data.data
        this.loadedDisputed = true
      }).catch(() => {
      })
    },
    getOrdersCancelled (page, status = 8) {
      this.loadedProgress = false
      ApiService.getOrdersByStatus(page, this.type, status, 6, this.userHash).then(response => {
        this.ordersCancelled = response.data.data
        this.loadedCancelled = true
      }).catch(() => {
      })
    },
    getOrdersExpired (page, status = 9) {
      this.loadExpired = false
      ApiService.getOrdersByStatus(page, this.type, status, 6, this.userHash).then(response => {
        this.ordersExpired = response.data.data
        this.loadExpired = true
      }).catch(() => {
      })
    },
    getOrdersDuplicate (page, status = 10) {
      this.loadedDuplicate = false
      ApiService.getOrdersByStatus(page, this.type, status, 6, this.userHash).then(response => {
        this.ordersDuplicate = response.data.data
        this.loadedDuplicate = true
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="container mb-3 mt-3">
    <table class="table table-striped table-bordered add-manage-table" data-filter="#filter" data-filter-text-only="true">
      <thead>
      <tr>
        <th>Username</th>
        <th>SSO ID</th>
        <th>Action by</th>
        <th>Account created</th>
        <th>Date account shadow banned</th>
        <th>Number of tasks</th>
        <th>Number of sales</th>
        <th>Number of purchases</th>
        <th>Country Code</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody class="mb-md-0 mb-3 text-left">
      <tr v-for="(user, index) in users" :key="index">
        <td @click="$router.push('/admin/users/' + user[0].hash)" class="cursor-pointer">{{user[0].username}}</td>
        <td>{{ user.electroneum_id }}</td>
        <td>{{ user.shadow_ban_applied_by }}</td>
        <td class="text-center">{{ date(user[0].date_created) }}</td>
        <td class="text-center">{{ date(user.shadow_ban_applied) }}</td>
        <td class="text-center">{{ user.tasks_total }}</td>
        <td class="text-center">{{ user.orders_sold }}</td>
        <td class="text-center">{{ user.orders_purchased }}</td>
        <td class="text-center">{{ user[0].country_iso !== null ? user[0].country_iso : 'No country set' }}</td>
        <td>
          <b-button class="mb-3 w-100" variant="warning" @click="shadowBan(user[0].hash, user[0].shadow_ban)">Remove shadow ban</b-button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ApiService from '../../../apiService'
export default {
  name: 'AdminUserShadowTable',
  props: {
    users: Array
  },
  methods: {
    date (date) {
      if (date === null) {
        return 'no date available'
      }
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    },
    shadowBan (hash, value) {
      let vm = this
      this.$swal({
        title: 'Removing shadow ban',
        text: 'This will remove shadow ban and user will appear in search',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: (result) => {
          if (result === true) {
            return ApiService.shadowBanUser(hash, value).then(() => {
            }).catch((error) => {
              this.$swal.showValidationMessage(
                error.response.data.error_message
              )
            })
          }
        }
      }).then((result) => {
        if (result.value === true) {
          this.$swal({
            title: 'Success',
            text: 'Shadow ban has been removed',
            type: 'success',
            confirmButtonText: 'Close'
          }).then(() => {
            vm.$vnode.context.getUsers()
          })
        }
      })
    }
  }
}
</script>

<template>
  <div>
    <div class="container">
      <Breadcrumb :breadcrumbList="[ { 'name': 'ADMIN DASHBOARD ', 'link': '/admin' },{ 'name': 'USERS', 'link': '/admin/users'}, {'name': this.statusText[this.userType]} ]"/>
      <div class="main-container">
        <h1 class="font-weight-bold">Manage User - {{ this.statusText[this.userType] }}</h1>
        <div class="container">
          <div class="col-xl-12 content-box">
            <div class="row row-featured row-featured-category">
              <div class="col-xl-12 box-title border-bottom">
                <div class="inner"><h2>Search for users</h2>
                </div>
              </div>
              <div class="container mb-3 mt-3">
                <div class="row">
                  <div class="col-10">
                    <b-nav-form @submit.prevent="getUsers(1)" class="searchBar w-100">
                      <b-input-group id="search">
                        <b-form-input v-model="postData.terms" type="text" ref="searchInput" placeholder="Search users"/>
                        <b-input-group-append>
                          <b-button variant="primary" size="sm" type="submit">
                            <span>
                                <i class="fa-solid fa-magnifying-glass"></i>
                            </span>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-nav-form>
                  </div>
                  <div class="col-2">
                    <select id="sortAdmin" @change="getUsers(1)" class="form-control float-right" v-model="postData.sort">
                      <option value="" disabled="disabled">Sort by</option>
                      <option value="created">Date created</option>
                      <option selected="selected" value="email">Email</option>
                      <option value="username">Username</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="container row mb-3" v-if="userType !== 'shadowBan'">
                <div class="col-3">
                  <select @change="getUsers(1)" v-model="postData.locked" class="form-control w-auto">
                    <option value="">Filter Locked Users</option>
                    <option value="1">Locked Users</option>
                    <option value="0">Un-Locked Users</option>
                  </select>
                </div>
                <div class="col-3">
                  <select @change="getUsers(1)" v-model="postData.account_type" class="form-control w-auto">
                    <option value="">Filter Account type</option>
                    <option value="-1">Unknowns</option>
                    <option value="1">Buyers</option>
                    <option value="0">Seller</option>
                  </select>
                </div>
                <div class="col-3">
                  <select  @change="getUsers(1)" v-model="postData.deleted" class="form-control w-auto">
                    <option value="">Filter Deleted Users</option>
                    <option value="1">Include Deleted Users</option>
                    <option value="0">Exclude Deleted Users</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="loadPage">
          <div class="container" v-if="showNoResults">
            <b-alert v-model="showNoResults" variant="success" dismissible>
              No users found, please try again
            </b-alert>
          </div>
          <div class="container">
            <div class="col-xl-12 content-box" v-if="users.length > 0">
              <div class="row row-featured row-featured-category">
                <div class="col-xl-12 box-title border-bottom ">
                  <p class="mt-3">Showing {{((currentPage - 1) * usersPerPage) + 1}} - {{(currentPage * usersPerPage) > totalUsers ? totalUsers : currentPage * usersPerPage}} of {{totalUsers}}</p>
                </div>
                <AdminUsersAllTable :users="users" v-if="this.userType === 'all'"/>
                <AdminUsersAwayTable :users="users" v-else-if="this.userType === 'away'"/>
                <AdminUserShadowTable :users="users" v-else-if="this.userType === 'shadowBan'"/>
                <AdminUsersWatched :users="users" v-else-if="this.userType === 'watched'"/>
              </div>
            </div>
            <Pagination :perPage="usersPerPage" :currentPage="currentPage" :total="totalUsers" v-on:paginationUpdate="updatePagination"  v-if="totalUsers > usersPerPage" class="mt-3"/>
          </div>
        </div>
        <div v-else>
          <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner"/>
          <div class="mb-5">Loading Users</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../../apiService.js'
import Pagination from '../../../components/Global/Pagination'
import AdminUsersAllTable from '../../../components/Admin/Users/AdminUsersAllTable'
import AdminUsersAwayTable from '../../../components/Admin/Users/AdminUsersAwayTable'
import AdminUserShadowTable from '../../../components/Admin/Users/AdminUserShadowTable'
import AdminUsersWatched from '../../../components/Admin/Users/AdminUsersWatched'
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'
export default {
  name: 'AdminUsers',
  components: {
    Pagination,
    AdminUsersAllTable,
    AdminUsersAwayTable,
    Breadcrumb,
    AdminUserShadowTable,
    AdminUsersWatched
  },
  data () {
    return {
      loadPage: true,
      users: [],
      currentPage: 1,
      usersPerPage: 25,
      totalUsers: 0,
      showNoResults: false,
      userType: this.$route.params.status,
      statusText: {
        'away': 'User Currently Away',
        'all': 'All Users',
        'shadowBan': 'Shadow Banned Users',
        'watched': 'Users currently being watched'
      },
      postData: {
        terms: '',
        locked: '',
        deleted: '1',
        account_type: '',
        sort: ''
      }
    }
  },
  methods: {
    getUsers (page = null) {
      if (page !== null) {
        this.currentPage = page
      }
      this.loadPage = false
      this.showNoResults = false

      const postData = {}
      for (let searchKey in this.postData) {
        if (this.postData[searchKey] !== '') {
          postData[searchKey] = this.postData[searchKey]
        }
      }
      switch (this.userType) {
        case 'away':
          postData['away'] = 1
          break
        case 'shadowBan':
          postData['shadow_ban'] = 4
          break
        case 'watched':
          postData['watched'] = 1
          break
      }
      ApiService.adminUserSearch(this.usersPerPage, this.currentPage, postData)
        .then(response => {
          this.users = response.data.data.results
          this.currentPage = response.data.data.pagination.page
          this.usersPerPage = response.data.data.pagination.per_page
          this.totalUsers = response.data.data.pagination.total
          if (this.users.length === 0) {
            this.showNoResults = true
          }
          this.loadPage = true
        }).catch(() => {
        })
    },
    updatePagination (page) {
      this.currentPage = page
      this.getUsers()
    }
  }
}
</script>

<style scoped>
#search {
  width: 100% !important;
}

.form-inline {
  width: 100%;
}

.container {
  max-width: 100%;
}
</style>

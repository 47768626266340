<template>
    <div>
      <div v-if="orders.pagination.total > 0">
        <div class="w-100 text-right mb-1">
          {{$t('total')}}: {{orders.pagination.total}}
        </div>
        <table class="table table-striped table-bordered add-manage-table table demo footable-loaded footable"
               data-filter="#filter" data-filter-text-only="true">
          <thead>
          <tr>
            <th v-if="userType === 'seller'">{{ $t('view_orders_table_heading_buyer') }}</th>
            <th v-else>{{ $t('view_orders_table_heading_seller') }}</th>
            <th>{{ $t('view_orders_table_heading_task') }}</th>
            <th>{{ $t('view_orders_table_heading_price') }}</th>
            <th>{{ $t('view_orders_table_heading_purchased') }}</th>
            <th>{{ $t('view_orders_table_heading_status') }}</th>
            <th v-if="type === 'cancelled'">Reason for Cancel</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(purchase, index) in orders.results" :key="index" class="mb-md-0 mb-3">
            <td v-if="userType === 'seller'" :data-title="$t('view_orders_table_heading_seller')">
                {{purchase.buyer_username }}
            </td>
            <td v-else :data-title="$t('view_orders_table_heading_buyer')">
              {{purchase.seller_username }}
            </td>
            <td :data-title="$t('view_orders_table_heading_task')">
                {{purchase.items[0].gig_title}}
            </td>
            <td :data-title="$t('view_orders_table_heading_price')">${{(purchase.price_total.toFixed(2) !== '0.00') ? purchase.price_total.toFixed(2) : purchase.price_subtotal.toFixed(2)}}
            </td>
            <td :data-title="$t('view_orders_table_heading_purchased')"> {{date(purchase.date_created) }}</td>
            <td :data-title="$t('view_orders_table_heading_status')">
              <span v-if="purchase.status[purchase.status.length - 1].status === 0" class="p-0">{{$t('job_waiting_for_decision')}}</span>
              <span v-else-if="purchase.status[purchase.status.length - 1 ].status === 1" class="p-0">{{$t('in_progress')}}</span>
              <span v-else-if="purchase.status[purchase.status.length - 1].status === 2" class="p-0">{{$t('job_declined')}}</span>
              <span v-else-if="purchase.status[purchase.status.length - 1].status === 3" class="p-0">{{$t('in_progress')}}</span>
              <span v-else-if="purchase.status[purchase.status.length - 1].status === 4" class="p-0">{{$t('delivered')}}</span>
              <span v-else-if="purchase.status[purchase.status.length - 1].status === 5" class="p-0">{{$t('completed')}}</span>
              <span v-else-if="purchase.status[purchase.status.length - 1].status === 6" class="p-0">{{$t('refund')}}</span>
              <span v-else-if="purchase.status[purchase.status.length - 1].status === 7" class="p-0">{{$t('disputed')}}</span>
              <span v-else-if="purchase.status[purchase.status.length - 1].status === 8" class="p-0">{{$t('cancelled')}}</span>
              <span v-else-if="purchase.status[purchase.status.length - 1].status === 9" class="p-0">Expired</span>
              <span v-else-if="purchase.status[purchase.status.length -1].status === 10" class="p-0">Duplicate</span>
              <span v-else class="p-0">{{$t('unknown')}}</span>
            </td>
            <td v-if="type === 'cancelled'">
              <span v-if="purchase.payments[purchase.payments.length - 1].status === 7">Order cancelled by us (AnyTask) automatically as seller did not respond</span>
              <span v-else-if="purchase.status[purchase.status.length - 1 ].user.username === purchase.buyer_username" >Order cancelled because buyer clicked cancel order</span>
              <span v-else-if="purchase.status[purchase.status.length - 1 ].user.username !== purchase.seller_username">Task closed due to account closed</span>
              <span v-else>Order not accepted by seller</span>
            </td>
          </tr>
          </tbody>
        </table>
        <Pagination :perPage="orders.pagination.per_page" :currentPage="orders.pagination.page" :total="orders.pagination.total" v-on:paginationUpdate="updateTable" class="mt-3"/>
      </div>
      <div v-else class="text-left">
        <p>{{ $t('orders_empty_heading') }}</p>
      </div>
    </div>
</template>

<script>
import Pagination from '../Global/Pagination'
export default {
  name: 'AdminOrdersTable',
  components: {
    Pagination
  },
  props: {
    'orders': {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    userType: {
      type: String,
      required: true
    }
  },
  methods: {
    date (date) {
      if (date === null) {
        return this.$t('no_payment_date_message')
      }
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    },
    updateTable (page) {
      if (this.type === 'all') {
        this.$parent.$parent.$parent.getOrders(page)
      } else if (this.type === 'acceptance') {
        this.$parent.$parent.$parent.getOrdersPending(page, 0)
      } else if (this.type === 'declined') {
        this.$parent.$parent.$parent.getOrdersDeclined(page, 2)
      } else if (this.type === 'delivered') {
        this.$parent.$parent.$parent.getOrdersDelivered(page, 4)
      } else if (this.type === 'completed') {
        this.$parent.$parent.$parent.getOrdersCompleted(page, 5)
      } else if (this.type === 'disputed') {
        this.$parent.$parent.$parent.getOrdersDisputed(page, 7)
      } else if (this.type === 'progress') {
        this.$parent.$parent.$parent.getOrdersProgress(page, 3)
      } else if (this.type === 'cancelled') {
        this.$parent.$parent.$parent.getOrdersCancelled(page, 8)
      } else if (this.type === 'expired') {
        this.$parent.$parent.$parent.getOrdersExpired(page, 9)
      } else if (this.type === 'duplicate') {
        this.$parent.$parent.$parent.getOrdersDuplicate(page, 10)
      }
    }
  }
}
</script>

<style scoped>

</style>

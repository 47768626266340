<template v-if="this.$store.getters.hasRole('ROLE_ADMIN_USER_BALANCE')">
  <b-modal id="modal_balance" size="l" :hide-footer=true title="User Balance" @hidden="reset">
  <div class="container">
    <div v-if="showSpinner" class="mb-3 text-center w-100">
      <b-spinner label="Updating" class="text-center "/>
    </div>
    <b-form v-else @submit="updateUserBalance" id="user-balance" class="pt-3 mb-3">
      <p v-if="error" class="alert alert-danger">{{ error }}</p>
      <p v-else-if="success" class="alert alert-success">User balance updated successfully.</p>

      <p>Update the user balance using the form below:</p>

      <div class="mb-3">
        <label for="user-balance-amount">Amount</label>
        <b-input-group prepend="$">
          <b-form-input
            id="user-balance-amount"
            type="number"
            step="0.01"
            v-model="amount"
            placeholder="123.45"
          ></b-form-input>
        </b-input-group>
      </div>

      <div class="mb-3">
        <label for="user-balance-expires">Expires</label>
        <b-input-group label-for="user-balance-expires">
          <b-form-input
            id="user-balance-expires"
            type="datetime-local"
            v-model="expires"
          ></b-form-input>
        </b-input-group>
      </div>

      <b-button id="user-balance-submit" type="submit" variant="primary">Update User Balance</b-button>
    </b-form>
  </div>
</b-modal>
</template>

<script>
import api from '../../../apiService'

export default {
  name: 'AdminUserBalance',
  props: {
    userHash: {
      type: String,
      required: true
    },
    userBalance: {
      type: Object,
      required: true
    }
  },
  watch: {
    'userBalance': function (item) {
      this.setData(item)
    }
  },
  data () {
    return {
      showSpinner: false,
      error: null,
      success: false,
      amount: null,
      expires: null
    }
  },
  methods: {
    reset () {
      this.showSpinner = false
      this.success = false
      this.error = null
    },
    setData (item) {
      this.amount = item.amount.toFixed(2)
      this.expires = item.date_expired.toString().split(':', 2).join(':')
    },
    updateUserBalance (e) {
      e.preventDefault()
      this.showSpinner = true
      this.success = false
      this.error = null

      if (!this.amount || !this.expires) {
        this.error = 'You must provide an amount and an expiry date.'
        this.showSpinner = false
      } else {
        api.updateUserBalance(this.userHash, this.userBalance.hash, parseFloat(this.amount), this.expires)
          .then(() => {
            this.success = true
            this.userBalance.amount = parseFloat(this.amount)
            this.userBalance.date_expired = this.expires
          })
          .catch((r) => {
            if (r.response.data.errors.children.amount.errors) {
              this.error = 'Amount: ' + r.response.data.errors.children.amount.errors[0]
            } else if (r.response.data.errors.children.date_expired.errors) {
              this.error = 'Expired: ' + r.response.data.errors.children.date_expired.errors[0]
            } else if (r.response.data.errors.errors) {
              this.error = r.response.data.errors.errors[0]
            } else {
              this.error = r.response.data.message
            }
          }).finally(() => {
            this.showSpinner = false
          })
      }
    }
  }
}
</script>

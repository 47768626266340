<template>
  <div>
    <div class="container" v-if="!loading">
      <Breadcrumb class="bg-transparent" :breadcrumbList="[
        { 'name': 'Admin Dashboard', 'link': $router.resolve({ name: 'admin' }).href },
        { 'name': 'Users', 'link': $router.resolve({ name: 'admin_users' }).href },
        { 'name': user.email + ' / ' + user.username }
      ]"/>
      <div class="main-container">
        <b-card no-body class="mt-2">
          <b-tabs card>
            <b-tab :title="$t('user_profile')" active>
              <AdminUserProfileTab :user="user" :lockedReason="lockedReason" :banReason="banReason"/>
            </b-tab>
            <b-tab :title="$t('user_roles')" v-on:click="activate('roles')" >
              <RolesForm :hash="user.hash" v-if="rolesTabActive" :userRoles="user.roles"/>
            </b-tab>
            <b-tab :title="$t('tasks')" v-on:click="activate('tasks')">
              <b-tabs card v-if="taskTabActive">
                <DashboardTasks :title="$t('tasksAll', {'count': this.tasksAll.length})" :tasks="this.tasksAll" :taskUser="this.user" :dropdown="false" :admin="true" :conversionRate="conversionRate"/>
                <DashboardTasks :title="$t('tasksActive', {'count': this.tasksActive.length})" :tasks="this.tasksActive" :taskUser="this.user" :dropdown="false" :admin="true" :conversionRate="conversionRate" />
                <DashboardTasks :title="$t('tasksPending', {'count': this.tasksPending.length})" :tasks="this.tasksPending" :taskUser="this.user" :dropdown="false" :admin="true"  :conversionRate="conversionRate" />
                <DashboardTasks :title="$t('tasksDeclined', {'count': this.tasksDeclined.length})" :tasks="this.tasksDeclined" :taskUser="this.user" :dropdown="false" :admin="true" :conversionRate="conversionRate" />
                <DashboardTasks :title="$t('tasksDraft', {'count': this.tasksInactive.length})" :tasks="this.tasksInactive" :taskUser="this.user" :dropdown="false" :admin="true" :conversionRate="conversionRate" />
                <DashboardTasks :title="$t('tasksUnlisted', {'count': this.tasksUnlisted.length})" :tasks="this.tasksUnlisted" :taskUser="this.user" :dropdown="false" :admin="true" :conversionRate="conversionRate" />
              </b-tabs>
              <div v-else class="mx-auto my-5">
                <b-spinner large label="Large Spinner"/>
                <div>{{ $t('loading') }}</div>
              </div>
            </b-tab>
            <b-tab :title="$t('reviews')" >
              <Reviews
                :average-rating="user.review_average.toFixed(2)"
                :review-count="user.review_count"
                :reviews="user.reviews"
                class="mb-4"
                :reviews-title="$t('seller_reviews')"
                v-if="user.reviews.length > 0"
              />
              <div v-else class="text-left">
                <p>{{ $t('reviews_empty_heading') }}</p>
              </div>
            </b-tab>
            <b-tab v-on:click="activate('sellerTab')" :title="$t('order_selling')" >
              <AdminViewOrdersTab v-if="sellerTabActive" type="seller" :userHash="user.hash"/>
            </b-tab>
            <b-tab v-on:click="activate('buyerTab')" :title="$t('order_purchased')" >
              <AdminViewOrdersTab v-if="buyerTabActive" type="buyer"  :userHash="user.hash"/>
            </b-tab>
            <b-tab title="Jobs" lazy v-if="$store.getters.hasRole('ROLE_ADMIN_JOB_BOARD')">
              <AdminUserJobs :username="user.username" />
            </b-tab>
            <b-tab title="Messages" v-on:click="loadMessages = true" >
              <AdminUserMessageTab :username="this.user.username" :userHash="this.user.hash" :loadMessages="loadMessages"/>
            </b-tab>
            <b-tab title="Gift Cards">
              <AdminGiftCardsTab :user="this.user" />
            </b-tab>
            <b-tab title="ETN Payments" v-if="$store.getters.hasRole('ROLE_ADMIN_SELLER_PAYMENT')">
              <AdminEtnPayments type="user" :hash="this.user.hash" />
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
    <div v-else>
      <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner"></b-spinner>
      <div class="mb-5">{{$t('loading_users')}}</div>
    </div>
  </div>
</template>

<script>
import RolesForm from '../../../components/Forms/RolesForm'
import ApiService from '../../../apiService.js'
import AdminUserProfileTab from '../../../components/Admin/Tabs/AdminUserProfileTab'
import Reviews from '../../../components/Task/Reviews'
import DashboardTasks from '../../../components/Tabs/DashboardTasks'
import AdminViewOrdersTab from '../../../components/Admin/Tabs/AdminViewOrdersTab'
import helper from '../../../helper'
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'
import AdminUserMessageTab from '../../../components/Admin/Tabs/AdminUserMessageTab'
import AdminGiftCardsTab from '../../../components/Admin/Tabs/AdminGiftCardsTab'
import AdminEtnPayments from '../../../components/Admin/Tabs/AdminEtnPayments'
import AdminUserJobs from '../../../components/Admin/Users/AdminUserJobs'

export default {
  name: 'AdminUserProfile',
  components: { AdminUserJobs, AdminEtnPayments, AdminGiftCardsTab, AdminUserMessageTab, Breadcrumb, RolesForm, AdminUserProfileTab, Reviews, DashboardTasks, AdminViewOrdersTab },
  data () {
    return {
      sellerTabActive: false,
      buyerTabActive: false,
      taskTabActive: false,
      user: null,
      loading: true,
      tasks: [],
      rolesTabActive: false,
      conversionRate: '',
      lockedReason: null,
      banReason: null,
      loadMessages: false
    }
  },
  mounted () {
    this.getUsers()
    this.getExchangeRate()
  },
  created () {
    window.addEventListener('scroll', helper.pauseVideoOnScroll)
  },
  computed: {
    tasksUnlisted: function () {
      return this.tasks.filter(function (t) {
        return t.status === 4
      })
    },
    tasksDeclined: function () {
      return this.tasks.filter(function (t) {
        return t.status === 3
      })
    },
    tasksPending: function () {
      return this.tasks.filter(function (t) {
        return t.status === 2
      })
    },
    tasksActive: function () {
      return this.tasks.filter(function (t) {
        return t.status === 1
      })
    },
    tasksInactive: function () {
      return this.tasks.filter(function (t) {
        return t.status === 0
      })
    },
    tasksAll: function () {
      return this.tasks.filter(function (t) {
        return true
      })
    }
  },
  methods: {
    getUsers () {
      this.loading = true
      ApiService.getUserAdmin(this.$route.params.hash).then((response) => {
        this.user = response.data.data[0]
        this.lockedReason = response.data.data['locked_reason']
        this.banReason = response.data.data['ban_reason']
        this.tasks = []
        this.loading = false
      }).catch(() => { })
    },
    getTasks () {
      ApiService.getTasksByUser(this.user.username).then(response => {
        this.tasks = response.data.data.gigs
        this.taskTabActive = true
      }).catch(() => { })
    },
    activate (type) {
      if (type === 'sellerTab') {
        this.sellerTabActive = true
      } else if (type === 'roles') {
        this.rolesTabActive = true
      } else if (type === 'tasks') {
        if (this.tasks.length === 0) {
          this.getTasks()
        }
      } else {
        this.buyerTabActive = true
      }
    },
    getExchangeRate () {
      ApiService.getConversionRate().then(response => {
        this.conversionRate = response.data.data['USD'].etn_rate
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped>
    .breadcrumbs a:link,
    .breadcrumbs a:visited,
    .breadcrumbs a:hover,
    .breadcrumbs a:active {
        text-decoration: none;
        color: #888;
    }

    .breadcrumbs:hover,
    .breadcrumbs a {
        cursor: default;
    }

    .breadcrumbs a.clickable:hover {
        cursor: pointer;
        color: #000 !important;
        text-decoration: underline !important;
    }

    .breadcrumbs {
        z-index: 3;
        color: #888 !important;
    }

    .breadcrumbs i {
        color: #888;
    }
</style>

import { render, staticRenderFns } from "./AdminUserBalance.vue?vue&type=template&id=15de9554&v-if=this.%24store.getters.hasRole('ROLE_ADMIN_USER_BALANCE')&"
import script from "./AdminUserBalance.vue?vue&type=script&lang=js&"
export * from "./AdminUserBalance.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div class="container mb-3 mt-3">
    <table class="table table-striped table-bordered add-manage-table" data-filter="#filter" data-filter-text-only="true">
      <thead>
      <tr>
        <th>Username</th>
        <th>SSO ID</th>
        <th>Account created</th>
        <th>Last active</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody class="mb-md-0 mb-3 text-left">
      <tr v-for="(user, index) in users" :key="index">
        <td @click="$router.push('/admin/users/' + user.hash)" class="cursor-pointer">{{ user.username }}</td>
        <td class="text-center">{{ user.electroneum_id }}</td>
        <td class="text-center">{{ date(user.date_created) }}</td>
        <td class="text-center">{{ date(user.last_active) }}</td>
        <td>
          <b-button class="mb-3 w-100" variant="warning" @click="unwatch(user.hash)">Remove watch</b-button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ApiService from '../../../apiService'

export default {
  name: 'AdminUserWatchTable',
  props: {
    users: Array
  },
  methods: {
    date (date) {
      if (date === null) {
        return 'no date available'
      }
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    },
    unwatch (hash) {
      let vm = this
      this.$swal({
        title: 'Unwatch users account',
        text: 'This will unwatch user account and their orders will no longer automatically go into the delayed payments',
        type: 'warning',
        input: 'textarea',
        inputPlaceholder: 'Please type your reason here for no longer watching users account',
        inputValidator: (value) => {
          if (!value) {
            return 'Please enter a reason'
          }
        },
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Unwatch account',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: (result) => {
          if (result !== '') {
            return ApiService.adminPatchUser(hash, { watched_reason: result, watched: 0 }).then((response) => {
            }).catch((error) => {
              this.$swal.showValidationMessage(
                error.response.data.error_message
              )
            })
          }
        }
      }).then((result) => {
        if (result.value !== '' && result.dismiss == null) {
          this.$swal({
            title: 'Success',
            text: 'Users account is no longer being watched',
            type: 'success',
            confirmButtonText: 'Close'
          }).then(() => {
            vm.$vnode.context.getUsers()
          })
        }
      })
    }
  }
}
</script>
